import React from 'react'

export default class MainScreen extends React.Component {


    render() {
        return (
            <div className="errres">
                <div className="mainerr">
                    <p className="bigerrtxt">
                        👋
                    </p>
                    <p className="errtxt">
                        I'm still alive !
                    </p>
                </div>
            </div>
        )
    }
}