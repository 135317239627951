import React from 'react'

export default class NotFound extends React.Component {

    render() {
        return (
            <div className="errres">
                <div className="mainerr">
                    <p className="bigerrtxt">
                        404
                    </p>
                    <p className="errtxt">
                        Page Link is Expired or Invalid
                    </p>
                </div>
            </div>
        )
    }
}