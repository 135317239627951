import { BrowserRouter, Route } from "react-router-dom";
import MainScreen from "./screens/Main";
import PlayScreen from "./screens/Pl";
import NotFound from "./screens/NotFound";

function App() {
  return (
<BrowserRouter>
      <Route path="/" component={MainScreen} exact></Route>
      <Route path="/:id" component={PlayScreen}></Route>
      <Route path="/not-found" component={NotFound} exact></Route>
    </BrowserRouter>
  );
}

export default App;
